import React, { forwardRef } from 'react'
import { useMultiStyleConfig } from '@chakra-ui/react'

import { FormControl, FormControlProps } from '../FormControl'
import { FormErrorMessage } from '../FormErrorMessage'
import { BaseRadio, BaseRadioProps } from './BaseRadio'

export type RadioProps = BaseRadioProps &
  Pick<FormControlProps, 'isDisabled' | 'isRequired' | 'id' | 'sx'> & {
    errorMessage?: string
  }

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ id, isDisabled, errorMessage, isRequired, sx, ...props }, ref) => {
    const styles = useMultiStyleConfig('Radio', {})
    return (
      <FormControl
        id={id}
        isDisabled={isDisabled}
        isInvalid={Boolean(errorMessage)}
        isRequired={isRequired}
        sx={sx}
      >
        <FormErrorMessage sx={styles.formError}>
          {errorMessage}
        </FormErrorMessage>
        <BaseRadio {...props} ref={ref} />
      </FormControl>
    )
  }
)
Radio.displayName = 'Radio'

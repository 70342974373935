import { accordionAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { getModeColor } from '../utils'

const baseStyleItem: SystemStyleFunction = (props) => ({
  paddingY: 2.5,
  borderColor: mode(
    'light.functional.divider',
    'dark.functional.divider'
  )(props),
  borderBottomWidth: '1px',
  marginBottom: 1,
  _last: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
})

const baseStyleButton: SystemStyleFunction = (props) => ({
  fontSize: '2xs',
  fontWeight: 'semibold',
  fontFamily: 'body',
  color: getModeColor('text.heading', props),
  lineHeight: 6,
})

const baseStylePanel: SystemStyleFunction = (props) => ({
  paddingBottom: 0,
  paddingBlockEnd: 0,
  paddingTop: 2,
  color: getModeColor('text.body', props),
})

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: baseStyleItem(props),
  button: baseStyleButton(props),
  panel: baseStylePanel(props),
})

export const Accordion = {
  parts: parts.keys,
  baseStyle,
}

const baseStyleContainer: SystemStyleFunction = (props) => ({
  bg: mode(
    'light.background.backgroundPrimary',
    'dark.background.backgroundPrimary'
  )(props),
  px: 4,
  py: 2,
})

export const AccordionContainer = {
  baseStyle: baseStyleContainer,
  variants: {
    card: {
      borderRadius: '2xl',
      boxShadow: 'dialog',
    },
  },
}

const baseStyleIcon: SystemStyleFunction = (props) => {
  const brandPrimary = getModeColor('brand.primary', props)

  return {
    color: brandPrimary,
    w: '1.5rem',
    h: '1.5rem',
  }
}

export const AccordionIcon = {
  baseStyle: baseStyleIcon,
}

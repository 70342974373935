import React, { forwardRef } from 'react'

import {
  InputProps as ChakraInputProps,
  Input as ChakraInput,
  InputGroup as ChakraInputGroup,
  InputRightElement as ChakraInputRightElement,
  InputLeftElement as ChakraInputLeftElement,
  useMultiStyleConfig,
  isStyleProp,
} from '@chakra-ui/react'
import { objectFilter } from '@chakra-ui/utils'

import type { StyleProps } from '../../core/style-props'

export type InputProps = Pick<
  ChakraInputProps,
  | 'autoComplete'
  | 'defaultValue'
  | 'id'
  | 'inputMode'
  | 'isDisabled'
  | 'isRequired'
  | 'isFullWidth'
  | 'isInvalid'
  | 'isReadOnly'
  | 'maxLength'
  | 'name'
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
  | 'pattern'
  | 'placeholder'
  | 'type'
  | 'value'
  | 'sx'
> & {
  size?: never
  leftElement?: React.ReactNode
  rightElement?: React.ReactNode
  tabIndex?: number | undefined
} & StyleProps

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ tabIndex, rightElement, leftElement, ...props }, ref) => {
    const styles = useMultiStyleConfig('Input', {})
    const styleProps = objectFilter(props, (_, prop) => isStyleProp(prop))
    const rest = objectFilter(props, (_, prop) => !isStyleProp(prop))

    if (!rightElement && !leftElement)
      return <ChakraInput {...props} ref={ref} />

    return (
      <ChakraInputGroup {...styleProps}>
        {leftElement && (
          <ChakraInputLeftElement sx={styles.icon} children={leftElement} />
        )}
        <ChakraInput {...rest} ref={ref} tabIndex={tabIndex} />
        {rightElement ? (
          <ChakraInputRightElement sx={styles.icon} children={rightElement} />
        ) : undefined}
      </ChakraInputGroup>
    )
  }
)

Input.displayName = 'Input'

import React, { forwardRef } from 'react'
import { Input, InputProps } from '../Input'
import { InputField, InputFieldProps } from '../InputField'

export enum INPUT_TYPES {
  text = 'text',
  email = 'email',
  number = 'number',
  password = 'password',
  tel = 'tel',
}

export interface TextInputProps
  extends InputFieldProps,
    Pick<
      InputProps,
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'placeholder'
      | 'value'
      | 'defaultValue'
      | 'rightElement'
      | 'leftElement'
    > {
  /** The type of input to render  */
  type?: keyof typeof INPUT_TYPES
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      defaultValue,
      errorMessage,
      label,
      hintText,
      id,
      isDisabled,
      isRequired,
      name,
      onBlur,
      onChange,
      placeholder,
      rightElement,
      leftElement,
      type = INPUT_TYPES.text,
      value,
      ...props
    },
    ref
  ) => {
    return (
      <InputField
        errorMessage={errorMessage}
        hintText={hintText}
        id={id}
        isDisabled={isDisabled}
        isRequired={isRequired}
        label={label}
        {...props}
      >
        <Input
          defaultValue={defaultValue}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={value}
          rightElement={rightElement}
          leftElement={leftElement}
        />
      </InputField>
    )
  }
)

TextInput.displayName = 'TextInput'

import React, { forwardRef } from 'react'
import { DateInputField, DateInputFieldProps } from './DateInputField'

export const YearInput = forwardRef<HTMLInputElement, DateInputFieldProps>(
  ({ label = 'Year', ...props }, ref) => {
    return (
      <DateInputField
        {...props}
        label={label}
        maxLength={4}
        ref={ref}
        width="4.25rem"
      />
    )
  }
)

YearInput.displayName = 'YearInput'

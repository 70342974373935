import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import Select, {
  ActionMeta,
  InputActionMeta,
  SelectInstance,
} from 'react-select'
import { isStyleProp, useMergeRefs } from '@chakra-ui/react'
import { objectFilter } from '@chakra-ui/utils'

import { useMultiStyleConfig } from '@chakra-ui/react'
import { InputField } from '../InputField'
import { InputProps } from '../'

import type { StyleProps } from '../../core/style-props'
import getAutoSelectStyles from './utils/AutoSuggestStyleTranformHelper'
import {
  AutoSuggestOption,
  AutoSuggestOptionComponent,
} from './utils/AutoSuggestOption'
import AutoSuggestInput from './utils/AutoSuggestInput'

import { IconButton } from '../..'
import { Box } from '../Box'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'

export type { AutoSuggestOption as Option }

export type Props = {
  readonly isDisabled?: boolean
  readonly isLoading?: boolean
  readonly isRtl?: boolean
  readonly options: AutoSuggestOption[]
  readonly menuIsOpen?: boolean
  inputValue?: string
  setInputValue?: Dispatch<SetStateAction<string>>
  errorMessage?: string
  hintText?: string
  id: string
  isRequired?: boolean
  isSearchable?: boolean
  clearOnBlur?: boolean
  label: string
  menuPortalTarget?: HTMLElement | null
  noOptionsMessage?(inputValue: unknown): React.ReactNode
  filterOption?(
    option: FilterOptionOption<unknown>,
    inputValue: string
  ): boolean
} & Pick<InputProps, 'name' | 'onBlur' | 'onFocus'> & {
    onSelect?(newValue: unknown): void
    onChange?(newValue: string): void
  } & StyleProps

export const AutoSuggest = forwardRef<SelectInstance, Props>(
  (
    {
      isDisabled,
      isLoading,
      isRtl,
      options,
      errorMessage,
      hintText,
      id,
      isRequired,
      label,
      inputValue,
      menuIsOpen,
      isSearchable = true,
      clearOnBlur = false,
      menuPortalTarget = document.querySelector('body'),
      setInputValue,
      noOptionsMessage,
      onFocus,
      onBlur,
      onChange,
      onSelect,
      filterOption,
      ...props
    },
    ref
  ) => {
    const autoSuggestTheme = useMultiStyleConfig('AutoSuggest', {}) as Record<
      string,
      any
    >
    const styleProps = objectFilter(props, (_, prop) => isStyleProp(prop))
    const autoSelectStyles = getAutoSelectStyles(autoSuggestTheme, errorMessage)
    const [showClearIndicator, setShowClearIndicator] = useState(false)
    const [menuShouldBeOpen, setMenuShouldBeOpen] = useState(false)
    const internalRef = useRef<SelectInstance>(null)
    const refs = useMergeRefs(internalRef, ref)

    useEffect(() => {
      if (inputValue) {
        setMenuShouldBeOpen(true)
        setShowClearIndicator(true)
        if (internalRef && internalRef.current) {
          internalRef.current.openMenu('first')
        }
      } else {
        setMenuShouldBeOpen(false)
      }
    }, [inputValue])

    const handleInputChanged = (input: string, reason: InputActionMeta) => {
      if (reason.action === 'set-value') {
        setMenuShouldBeOpen(false)
        setShowClearIndicator(true)
      }
      if (reason.action === 'input-change') {
        setShowClearIndicator(true)
        if (onChange) {
          onChange(input)
          if (setInputValue) setInputValue(input)
          setShowClearIndicator(true)
          setMenuShouldBeOpen(true)

          if (input === '') {
            internalRef.current?.clearValue()
            setShowClearIndicator(false)
            setMenuShouldBeOpen(false)
          }
        }
      }
    }

    const handleOnChange = (
      newValue: unknown,
      actionMeta: ActionMeta<unknown>
    ) => {
      setShowClearIndicator(true)
      const newValueAsOption = newValue as AutoSuggestOption
      if (onChange && newValueAsOption && newValueAsOption.label) {
        onChange(newValueAsOption.label)
      }
      if (
        actionMeta.action === 'select-option' &&
        newValueAsOption &&
        newValueAsOption.label
      ) {
        if (setInputValue) setInputValue(newValueAsOption.label)
        if (onSelect) onSelect(newValue)
      }
      if (actionMeta.action === 'clear') {
        if (setInputValue) setInputValue('')
      }
    }

    const handleOnBlur = (
      event: React.FocusEvent<HTMLInputElement, Element>
    ) => {
      if (
        clearOnBlur &&
        setInputValue &&
        inputValue &&
        options.findIndex((option) => option.label === inputValue) === -1
      ) {
        setInputValue('')
      }

      if (onBlur) onBlur(event)
    }

    const handleOnFocus = (
      event: React.FocusEvent<HTMLInputElement, Element>
    ) => {
      setMenuShouldBeOpen(true)
      if (onFocus) onFocus(event)
    }

    return (
      <InputField
        id={id}
        errorMessage={errorMessage}
        hintText={hintText}
        isDisabled={isDisabled}
        isRequired={isRequired}
        label={label}
        {...styleProps}
      >
        <Select
          inputId={id}
          options={options}
          isClearable={true}
          placeholder=""
          isSearchable={isSearchable}
          components={{
            ClearIndicator: () => null,
            DropdownIndicator: () => null,
            Option: AutoSuggestOptionComponent,
            Input: AutoSuggestInput,
          }}
          styles={autoSelectStyles}
          ref={refs}
          menuIsOpen={menuShouldBeOpen && menuIsOpen}
          menuPortalTarget={menuPortalTarget}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isRtl={isRtl}
          inputValue={inputValue}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onInputChange={handleInputChanged}
          noOptionsMessage={noOptionsMessage}
          filterOption={filterOption}
        />
        {showClearIndicator && (
          <Box sx={autoSuggestTheme.iconWrapper}>
            <IconButton
              aria-label="clear value"
              variant="autosuggest-clear"
              icon="Close"
              onClick={() => {
                if (setInputValue) {
                  internalRef.current?.clearValue()
                  setInputValue('')
                }
                setShowClearIndicator(inputValue == '')
              }}
            />
          </Box>
        )}
      </InputField>
    )
  }
)

AutoSuggest.displayName = 'AutoSuggest'

import { ChangeEvent, RefObject } from 'react'
import { isValidFormat } from './utils'

export enum DATE_INPUT_TYPES {
  month = 'month',
  day = 'day',
  year = 'year',
}

interface useDateInputProps {
  onChange?(date: string): void
  value?: string
  refDay: RefObject<HTMLInputElement>
  refMonth: RefObject<HTMLInputElement>
  refYear: RefObject<HTMLInputElement>
}

export const useDateInput = ({
  onChange,
  refDay,
  refMonth,
  refYear,
  value,
}: useDateInputProps) => {
  const getDateValues = (value?: string) => {
    if (value && isValidFormat(value)) {
      const date = value.split('-')
      return {
        day: date[2],
        month: date[1],
        year: date[0],
      }
    } else {
      const day = refDay?.current ? refDay.current.value : ''
      const month = refMonth?.current ? refMonth.current.value : ''
      const year = refYear?.current ? refYear.current.value : ''

      return {
        day,
        month,
        year,
      }
    }
  }

  const onChangeDate = (
    e: ChangeEvent<HTMLInputElement>,
    type: DATE_INPUT_TYPES
  ) => {
    if (!onChange) return

    const eventValue = e.target.value
    const inputValues = getDateValues(value)

    inputValues[type] = eventValue
    const { day, month, year } = inputValues

    let formattedMonth = month
    let formattedDay = day
    let formattedYear = year
    if (month.length > 0 && Number(month) < 10)
      formattedMonth = `0${Number(month)}`
    if (day.length > 0 && Number(day) < 10) formattedDay = `0${Number(day)}`
    while (formattedYear.length > 0 && formattedYear.length < 4)
      formattedYear = `0${formattedYear}`

    onChange(`${formattedYear}-${formattedMonth}-${formattedDay}`)
  }

  const onChangeMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeDate(e, DATE_INPUT_TYPES.month)
  }

  const onChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeDate(e, DATE_INPUT_TYPES.day)
  }

  const onChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeDate(e, DATE_INPUT_TYPES.year)
  }

  return {
    onChangeDay,
    onChangeMonth,
    onChangeYear,
    getDateValues,
  }
}

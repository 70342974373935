import React from 'react'
import {
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
  VStack,
  Box,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { InputField, InputFieldProps } from '../InputField'
import { Divider } from '../Divider'

export type RadioGroupProps = Pick<
  ChakraRadioGroupProps,
  'defaultValue' | 'name' | 'onChange' | 'value'
> &
  InputFieldProps

export const RadioGroup = ({
  children,
  errorMessage,
  hintText,
  id,
  isDisabled,
  isRequired,
  label,
  ...props
}: RadioGroupProps) => {
  const styles = useMultiStyleConfig('Radio', {})

  return (
    <InputField
      as="fieldset"
      errorMessage={errorMessage}
      hintText={hintText}
      id={id}
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
    >
      <Box sx={styles.groupContainer}>
        <ChakraRadioGroup {...props}>
          <VStack divider={<Divider />} align="left" spacing={4}>
            {children}
          </VStack>
        </ChakraRadioGroup>
      </Box>
    </InputField>
  )
}

RadioGroup.displayName = 'RadioGroup'

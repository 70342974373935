import React, { forwardRef } from 'react'
import {
  Center,
  Tag as ChakraChip,
  TagProps as ChakraChipProps,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { Icon } from '../Icon'
import { Box } from '../Box'
import type { StyleProps } from '../../core/style-props'

export type BaseChipProps = Pick<
  ChakraChipProps,
  'children' | 'onClick' | 'as'
> & {
  selected?: boolean
} & StyleProps

export const Chip = forwardRef<HTMLButtonElement, BaseChipProps>(
  (props, ref) => {
    const { as, selected, children, onClick } = props
    const style = useMultiStyleConfig('Chip', {
      variant: selected ? 'selected' : '',
    })

    return (
      <ChakraChip
        as={as}
        onClick={onClick}
        sx={style.container}
        ref={ref}
        {...props}
      >
        <Center>
          {selected ? (
            <Icon icon="Confirm" marginRight={1.5} sx={style.icon} />
          ) : undefined}
          <Box>{children}</Box>
        </Center>
      </ChakraChip>
    )
  }
)

Chip.displayName = 'Chip'

import React from 'react'
import {
  Box,
  useCheckboxGroup,
  Wrap,
  WrapItem,
  WrapProps,
} from '@chakra-ui/react'
import { CheckboxChip } from '../Chip/CheckboxChip'
import { StringOrNumber } from '@chakra-ui/utils'
import { ChipOptions } from '.'

export type CheckboxChipListProps = {
  options: ChipOptions[]
  onChange?: (selectedChips: string[]) => void
  defaultValue?: StringOrNumber[]
  isDisabled?: boolean
  isFocusable?: boolean
  name?: string
  value?: StringOrNumber[]
} & Pick<WrapProps, 'justify'>

export function CheckboxChipList(props: CheckboxChipListProps) {
  const { options, justify } = props

  const { getCheckboxProps } = useCheckboxGroup({
    onChange: props.onChange,
    defaultValue: props.defaultValue,
    isDisabled: props.isDisabled,
    value: props.value,
  })

  return (
    <Box>
      <Wrap role="group" justify={justify} spacing={0}>
        {options.map(({ value, label }) => {
          const checkbox = getCheckboxProps({ value })
          return (
            <WrapItem key={value} paddingRight={2} paddingBottom={3}>
              <CheckboxChip {...checkbox}>{label}</CheckboxChip>
            </WrapItem>
          )
        })}
      </Wrap>
    </Box>
  )
}

import React, { forwardRef } from 'react'
import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  useMultiStyleConfig,
  useStyleConfig,
} from '@chakra-ui/react'

import { Icon } from '../..'
import { IconType } from '../Icon/iconRegistry'

import type { StyleProps } from '../../core/style-props'

export type IconButtonProps = Pick<
  ChakraIconButtonProps,
  | 'aria-pressed'
  | 'aria-label'
  | 'isActive'
  | 'isDisabled'
  | 'isLoading'
  | 'isRound'
  | 'spinner'
  | 'children'
  | 'sx'
> & {
  icon?: IconType
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'input-icon'
    | 'autosuggest-clear'
  onClick?(): void
} & StyleProps

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ sx, variant = 'primary', onClick, isDisabled, ...props }, ref) => {
    const style = useMultiStyleConfig('IconButton', {
      variant: variant,
    })
    const { w, h } = useStyleConfig('Icon', { variant })
    const sxStyle = w === undefined && h === undefined ? {} : { w, h }
    return (
      <ChakraIconButton
        icon={props.icon ? <Icon icon={props.icon} sx={sxStyle} /> : undefined}
        sx={sx}
        __css={style}
        ref={ref}
        aria-label={props['aria-label']}
        aria-pressed={props['aria-pressed']}
        variant={variant}
        onClick={onClick}
        isDisabled={isDisabled}
      >
        {props.children}
      </ChakraIconButton>
    )
  }
)

IconButton.displayName = 'IconButton'

import { WrapProps } from '@chakra-ui/react'
import { StringOrNumber } from '@chakra-ui/utils'
import React from 'react'
import { CheckboxChipList, CheckboxChipListProps } from './CheckboxChipList'
import { SingleSelectChipList } from './SingleSelectChipList'

import type { StyleProps } from '../../core/style-props'

export type ChipOptions = {
  label: string
  value: string
}

export type ChipListProps = Pick<
  CheckboxChipListProps,
  'isDisabled' | 'isFocusable' | 'name'
> & {
  chips: ChipOptions[]
  onChipSelect?: (selectedChips: string | string[]) => void
  selectType?: 'one' | 'multiple'
  defaultValue?: StringOrNumber[]
  value?: StringOrNumber[]
} & StyleProps &
  Pick<WrapProps, 'justify'>

export const ChipList = (props: ChipListProps) => {
  const {
    chips,
    selectType = 'one',
    onChipSelect,
    defaultValue,
    value,
    ...rest
  } = props

  return selectType === 'multiple' ? (
    <CheckboxChipList
      options={chips}
      onChange={onChipSelect}
      defaultValue={defaultValue}
      value={value}
      {...rest}
    />
  ) : (
    <SingleSelectChipList
      options={chips}
      onChange={onChipSelect}
      defaultValue={defaultValue}
      value={value}
      {...rest}
    />
  )
}

ChipList.displayName = 'ChipList'

import React, { ReactNode } from 'react'
import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as ChakraFormErrorMessageProps,
  HStack,
  HTMLChakraProps,
} from '@chakra-ui/react'
import { Icon } from '../Icon'

export type FormErrorMessageProps = Pick<
  ChakraFormErrorMessageProps,
  keyof HTMLChakraProps<'div'>
> & {
  children: ReactNode
}

export const FormErrorMessage = ({
  children,
  ...props
}: FormErrorMessageProps) => {
  return (
    <ChakraFormErrorMessage {...props}>
      <HStack spacing={2}>
        <Icon icon="AlertError" />
        <span>{children}</span>
      </HStack>
    </ChakraFormErrorMessage>
  )
}

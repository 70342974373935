import React, { forwardRef } from 'react'
import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
} from '@chakra-ui/react'
import type { StyleProps } from '../../core/style-props'

export type FormLabelProps = Pick<
  ChakraFormLabelProps,
  'children' | 'as' | 'requiredIndicator' | 'htmlFor' | 'id' | 'sx'
> &
  StyleProps

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  ({ sx, ...props }, ref) => <ChakraFormLabel {...props} ref={ref} sx={sx} />
)

FormLabel.displayName = 'FormLabel'

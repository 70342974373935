import React from 'react'
import { components, OptionProps } from 'react-select'

export type AutoSuggestOption = {
  value: string
  label: string
  hintText?: string
}

function getOption(option: any): AutoSuggestOption {
  const data =
    'value' in option && 'label' in option && 'hintText' in option
      ? option
      : null
  return data
}

export const AutoSuggestOptionComponent = (props: OptionProps) => {
  const optionData = getOption(props.data)
  return optionData ? (
    <components.Option {...props}>
      <div ref={props.innerRef}>
        <div className="option__label">{optionData.label}</div>
        <div className="option__hintText">{optionData.hintText}</div>
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>
      <div ref={props.innerRef}>
        <div className="option__label">{props.label}</div>
        <div className="option__hintText">{props.label}</div>
      </div>
    </components.Option>
  )
}

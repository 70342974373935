import React from 'react'
import {
  Progress as ChakraProgress,
  ProgressProps as ChakraProgressProps,
} from '@chakra-ui/react'

import type { StyleProps } from '../../core/style-props'

export type ProgressIndicatorProps = Pick<
  ChakraProgressProps,
  'value' | 'max' | 'min'
> &
  StyleProps

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  value,
  max,
  min,
  ...props
}) => {
  return <ChakraProgress value={value} max={max} min={min} {...props} />
}

ProgressIndicator.displayName = 'ProgressIndicator'

import React, { forwardRef } from 'react'

import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react'

import { InputField, InputFieldProps } from '../InputField'

export type TextareaProps = Pick<
  ChakraTextareaProps,
  | 'isDisabled'
  | 'isFullWidth'
  | 'isInvalid'
  | 'isReadOnly'
  | 'placeholder'
  | 'value'
  | 'onBlur'
  | 'onChange'
  | 'onFocus'
  | 'sx'
> & { size?: never } & InputFieldProps

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      sx,
      errorMessage,
      hintText,
      id,
      isDisabled,
      isRequired,
      label,
      placeholder,
      isInvalid,
      onBlur,
      onChange,
      onFocus,
      value,
      ...props
    },
    ref
  ) => {
    return (
      <InputField
        errorMessage={errorMessage}
        hintText={hintText}
        id={id}
        isDisabled={isDisabled}
        isRequired={isRequired}
        label={label}
        {...props}
      >
        <ChakraTextarea
          isRequired={isRequired}
          isDisabled={isDisabled}
          placeholder={placeholder}
          isInvalid={isInvalid}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          onChange={onChange}
          sx={sx}
          ref={ref}
        />
      </InputField>
    )
  }
)

Textarea.displayName = 'Textarea'

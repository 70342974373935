import React, { Fragment, useRef } from 'react'
import {
  FormHelperText,
  useMultiStyleConfig,
  isStyleProp,
} from '@chakra-ui/react'
import { objectFilter } from '@chakra-ui/utils'
import type { StyleProps } from '../../core/style-props'

import { Stack } from '../Stack'
import { InputProps } from '../Input'
import { DayInput } from './DayInput'
import { MonthInput } from './MonthInput'
import { YearInput } from './YearInput'
import { DATE_INPUT_TYPES, useDateInput } from './useDateInput'
import { isValidFormat } from './utils'
import { FormControl, FormControlProps } from '../FormControl'
import { FormLabel } from '../FormLabel'
import { FormErrorMessage } from '../FormErrorMessage'
import { Box } from '../Box'
import { Heading } from '../Heading'

export type DateInputProps = Pick<InputProps, 'autoComplete' | 'onBlur'> &
  Pick<FormControlProps, 'children' | 'id' | 'isDisabled' | 'isRequired'> & {
    dayLabel?: string
    defaultValue?: string
    errorMessage?: string
    hintText?: string
    label?: string
    locale?: string
    monthLabel?: string
    value?: string
    yearLabel?: string
    onChange?(date: string): void
  } & StyleProps

type DateComponents = { [key: string]: JSX.Element }

export const DateInput = ({
  autoComplete,
  dayLabel = 'Day',
  defaultValue,
  errorMessage,
  hintText,
  id = 'date',
  isDisabled,
  isRequired,
  label,
  locale = 'en-US',
  monthLabel = 'Month',
  value,
  yearLabel = 'Year',
  onBlur,
  onChange,
  ...props
}: DateInputProps): React.ReactElement => {
  const styles = useMultiStyleConfig('DateInput', {})
  const refMonth = useRef<HTMLInputElement>(null)
  const refDay = useRef<HTMLInputElement>(null)
  const refYear = useRef<HTMLInputElement>(null)
  const styleProps = objectFilter(props, (_, prop) => isStyleProp(prop))

  const { onChangeMonth, onChangeDay, onChangeYear, getDateValues } =
    useDateInput({
      value,
      refDay,
      refMonth,
      refYear,
      onChange,
    })

  const { day, month, year } = getDateValues(
    value && isValidFormat(value) ? value : defaultValue
  )

  const commonProps = {
    autoComplete,
    isInvalid: Boolean(errorMessage),
    isDisabled,
    onBlur,
  }

  const inputs: DateComponents = {
    [DATE_INPUT_TYPES.day]: (
      <DayInput
        {...commonProps}
        id={`${id}-day`}
        defaultValue={day}
        label={dayLabel}
        ref={refDay}
        onChange={onChangeDay}
      />
    ),
    [DATE_INPUT_TYPES.month]: (
      <MonthInput
        {...commonProps}
        id={`${id}-month`}
        defaultValue={month}
        label={monthLabel}
        ref={refMonth}
        onChange={onChangeMonth}
      />
    ),
    [DATE_INPUT_TYPES.year]: (
      <YearInput
        {...commonProps}
        id={`${id}-year`}
        defaultValue={year}
        label={yearLabel}
        ref={refYear}
        onChange={onChangeYear}
      />
    ),
  }

  let filteredParts: string[] = ['day', 'month', 'year']

  if (locale.substring(locale.length - 2) === 'US') {
    filteredParts = ['month', 'day', 'year']
  }

  const ariaDescribedbyIds = [
    { id: `${id}-helptext`, hasProp: Boolean(hintText) },
    { id: `${id}-feedback`, hasProp: Boolean(errorMessage) },
  ]

  const ariaDescribedbyText = ariaDescribedbyIds
    .filter((value) => value.hasProp)
    .map((value) => value.id)
    .join(' ')
    .trim()

  const ariaDescribedby =
    ariaDescribedbyText !== '' ? ariaDescribedbyText : undefined

  return (
    <FormControl
      id={id}
      isDisabled={isDisabled}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
      as="fieldset"
      ariaDescribedby={ariaDescribedby}
      {...styleProps}
    >
      {label && (
        <FormLabel as={'legend'}>
          <Heading size={'xs'} sx={styles.heading}>
            {label}
          </Heading>
        </FormLabel>
      )}
      {hintText && <FormHelperText marginTop={1}>{hintText}</FormHelperText>}
      <FormErrorMessage marginTop={2}>{errorMessage}</FormErrorMessage>
      {(label || hintText || errorMessage) && <Box marginTop={2} />}
      <Stack direction="row" spacing={3}>
        {filteredParts.map((type) => {
          return <Fragment key={type}>{inputs[type]}</Fragment>
        })}
      </Stack>
    </FormControl>
  )
}

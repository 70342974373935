import React, { forwardRef } from 'react'
import { DateInputField, DateInputFieldProps } from './DateInputField'

export const DayInput = forwardRef<HTMLInputElement, DateInputFieldProps>(
  ({ label = 'Day', ...props }, ref) => {
    return (
      <DateInputField
        {...props}
        label={label}
        maxLength={2}
        ref={ref}
        width="3.25rem"
      />
    )
  }
)

DayInput.displayName = 'DayInput'

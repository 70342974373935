import React, { ReactNode, forwardRef } from 'react'
import {
  Box,
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import { Text } from '../Text'

export type BaseRadioProps = ChakraRadioProps & {
  children?: ReactNode
  label: ChakraRadioProps['children']
  hintText?: string
}

export const BaseRadio = forwardRef<HTMLInputElement, BaseRadioProps>(
  ({ children, hintText, label, ...props }, ref) => {
    const styles = useMultiStyleConfig('Radio', {})

    return (
      <>
        <ChakraRadio {...props} ref={ref}>
          {label}
          {hintText && (
            <Text as="span" sx={styles.hintText} textStyle="caption">
              {hintText}
            </Text>
          )}
        </ChakraRadio>
        {children && <Box sx={styles.content}>{children}</Box>}
      </>
    )
  }
)

import React, { forwardRef } from 'react'
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react'

import { Icon } from '../Icon'

export type BaseSelectProps = Pick<
  ChakraSelectProps,
  | 'name'
  | 'errorBorderColor'
  | 'focusBorderColor'
  | 'isDisabled'
  | 'isInvalid'
  | 'isReadOnly'
  | 'isRequired'
  | 'rootProps'
  | 'sx'
>

export const BaseSelect = forwardRef<HTMLSelectElement, BaseSelectProps>(
  ({ sx, ...props }, ref) => {
    return (
      <ChakraSelect {...props} icon={<Icon icon="Down" />} ref={ref} sx={sx} />
    )
  }
)

BaseSelect.displayName = 'BaseSelect'

import React, { forwardRef } from 'react'
import {
  Badge as ChakraBadge,
  BadgeProps as ChakraBadgeProps,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import type { StyleProps } from '../../core/style-props'

export type BadgeProps = Pick<ChakraBadgeProps, 'children'> & {
  variant?:
    | 'primarySolid'
    | 'secondarySolid'
    | 'secondaryOutline'
    | 'primaryOutline'
} & StyleProps

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>((props, ref) => {
  const { variant } = props
  const style = useMultiStyleConfig('Badge', {
    variant: variant ? variant : 'primarySolid',
  })
  return <ChakraBadge {...props} ref={ref} sx={style} />
})

Badge.displayName = 'Badge'

import { isStyleProp } from '@chakra-ui/react'
import { objectFilter } from '@chakra-ui/utils'
import React, { forwardRef, useState } from 'react'
import { TextInput, IconButton, InputProps } from '../..'
import { InputFieldProps } from '../InputField'

export interface PasswordInputProps
  extends InputFieldProps,
    Pick<
      InputProps,
      'name' | 'onBlur' | 'onChange' | 'placeholder' | 'value' | 'defaultValue'
    > {
  /** The type of input to render  */
  showIcon?: boolean
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      defaultValue,
      errorMessage,
      label,
      hintText,
      id,
      isDisabled,
      isRequired,
      name,
      onBlur,
      onChange,
      placeholder,
      value,
      showIcon = true,
      ...props
    },
    ref
  ) => {
    const [fieldType, setFieldType] = useState('password')
    const passwordIcon = fieldType === 'text' ? 'Show' : 'Hide'
    const ariaLabel = fieldType === 'text' ? 'Hide' : 'Show'
    const styleProps = objectFilter(props, (_, prop) => isStyleProp(prop))
    const handleClick = () => {
      if (fieldType === 'text') setFieldType('password')
      else setFieldType('text')
    }
    return (
      <TextInput
        errorMessage={errorMessage}
        hintText={hintText}
        id={id}
        isDisabled={isDisabled}
        isRequired={isRequired}
        label={label}
        defaultValue={defaultValue}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        type={fieldType as 'password' | 'text'}
        value={value}
        rightElement={
          showIcon ? (
            <IconButton
              aria-label={`${ariaLabel} password`}
              variant="input-icon"
              onClick={handleClick}
              icon={passwordIcon}
            />
          ) : null
        }
        {...styleProps}
      />
    )
  }
)

PasswordInput.displayName = 'PasswordInput'

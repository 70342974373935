import React, { forwardRef } from 'react'
import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton as ChakraAccordionButton,
  AccordionPanel,
  AccordionProps as ChakraAccordionProps,
  AccordionButtonProps as ChakraAccordionButtonProps,
  useAccordionItemState,
  useStyleConfig,
} from '@chakra-ui/react'
import type { SystemStyleObject } from '@chakra-ui/theme-tools'
import type { StyleProps } from '../../core/style-props'

import { Box } from '../Box'
import { Icon } from '../Icon'

export type AccordionProps = Pick<
  ChakraAccordionProps,
  | 'allowMultiple'
  | 'allowToggle'
  | 'defaultIndex'
  | 'index'
  | 'onChange'
  | 'children'
> & { variant?: 'card' } & StyleProps

export const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (props, ref) => {
    const containerStyles = useStyleConfig('AccordionContainer', {
      variant: props.variant,
    })

    return (
      <Box __css={containerStyles}>
        <ChakraAccordion ref={ref} {...props} />
      </Box>
    )
  }
)

Accordion.displayName = 'Accordion'

Accordion.defaultProps = {
  ...ChakraAccordion.defaultProps,
  allowMultiple: true,
  allowToggle: true,
}

export const CardAccordion = forwardRef<HTMLDivElement, AccordionProps>(
  (props, ref) => <Accordion variant="card" ref={ref} {...props} />
)

CardAccordion.displayName = 'CardAccordion'

export type AccordionButtonProps = Pick<
  ChakraAccordionButtonProps,
  'children'
> &
  StyleProps

export const AccordionButton = forwardRef<
  HTMLButtonElement,
  AccordionButtonProps
>(({ children, ...props }, ref) => {
  const { isOpen } = useAccordionItemState()
  const styles = useStyleConfig('AccordionIcon')

  const iconStyles: SystemStyleObject = {
    transform: isOpen ? 'rotate(90deg)' : undefined,
    transition: 'transform 0.2s',
    transformOrigin: 'center',
    marginRight: '3',
    ...styles,
  }

  return (
    <ChakraAccordionButton ref={ref} {...props}>
      <Icon icon="Forward" sx={iconStyles} />
      {children}
    </ChakraAccordionButton>
  )
})

AccordionButton.displayName = 'AccordionButton'

export { AccordionItem, AccordionPanel }

AccordionItem.displayName = 'AccordionItem'
AccordionPanel.displayName = 'AccordionPanel'

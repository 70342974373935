import React, { forwardRef, ReactNode } from 'react'
import {
  List as ChakraList,
  UnorderedList as ChakraUnorderedList,
  OrderedList as ChakraOrderedList,
  ListProps as ChakraListProps,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import { Heading, HeadingLevel } from '../Heading'
import { Text } from '../Text'

import type { StyleProps } from '../../core/style-props'

export type ListProps = Pick<
  ChakraListProps,
  'spacing' | 'stylePosition' | 'styleType' | 'children' | 'as'
> &
  StyleProps

export const List = forwardRef<HTMLUListElement, ListProps>((props, ref) => {
  return <ChakraList {...props} ref={ref} />
})

List.displayName = 'List'

export type BulletListProps = {
  variant?: 'no-padding'
  heading?: ReactNode
  headingHint?: ReactNode
  headingAs?: HeadingLevel
} & ListProps

export const BulletList = forwardRef<HTMLUListElement, BulletListProps>(
  ({ variant, heading, headingHint, headingAs, as, ...rest }, ref) => {
    const styles = useMultiStyleConfig('List', {})

    return (
      <>
        {heading && (
          <Heading as={headingAs} size="md" sx={styles.heading}>
            {heading}
          </Heading>
        )}
        {headingHint && <Text sx={styles.hint}>{headingHint}</Text>}
        {!as && <ChakraList {...rest} ref={ref} />}
        {as && as !== 'ol' && (
          <ChakraUnorderedList variant={variant} as={as} {...rest} ref={ref} />
        )}
        {as === 'ol' && (
          <ChakraOrderedList
            variant={variant}
            as={as}
            {...rest}
            ref={ref as React.Ref<HTMLOListElement>}
          />
        )}
      </>
    )
  }
)

BulletList.displayName = 'BulletList'

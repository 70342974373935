import React, { ChangeEvent, useCallback } from 'react'
import {
  Box,
  useCheckboxGroup,
  Wrap,
  WrapItem,
  WrapProps,
} from '@chakra-ui/react'
import { isInputEvent, StringOrNumber } from '@chakra-ui/utils'
import { ChipOptions } from '.'
import { CheckboxChip } from '../Chip/CheckboxChip'

type EventOrValue = ChangeEvent<HTMLInputElement> | StringOrNumber

export type SingleSelectChipListProps = {
  options: ChipOptions[]
  onChange?: (selectedChips: string[]) => void
  name?: string
  defaultValue?: StringOrNumber[]
  isDisabled?: boolean
  value?: StringOrNumber[]
  isFocusable?: boolean
} & Pick<WrapProps, 'justify'>

export function SingleSelectChipList(props: SingleSelectChipListProps) {
  const { options, justify } = props
  const { getCheckboxProps, setValue, value } = useCheckboxGroup({
    onChange: props.onChange,
    defaultValue: props.defaultValue,
    isDisabled: props.isDisabled,
    value: props.value,
  })

  const handleChange = useCallback(
    (eventOrValue: EventOrValue) => {
      if (!value) return

      const isChecked = isInputEvent(eventOrValue)
        ? eventOrValue.target.checked
        : !value.includes(eventOrValue)

      const selectedValue = isInputEvent(eventOrValue)
        ? eventOrValue.target.value
        : eventOrValue
      const nextValue = isChecked
        ? [selectedValue]
        : value.filter((v) => String(v) !== String(selectedValue))

      setValue(nextValue)
    },
    [setValue, value]
  )

  return (
    <Box>
      <Wrap role="group" justify={justify} spacing={0}>
        {options.map(({ value, label }) => {
          const checkbox = getCheckboxProps({ value })
          return (
            <WrapItem key={value} paddingRight={2} paddingBottom={3}>
              <CheckboxChip {...checkbox} onChange={handleChange}>
                {label}
              </CheckboxChip>
            </WrapItem>
          )
        })}
      </Wrap>
    </Box>
  )
}

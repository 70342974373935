import React from 'react'
import { Box, useCheckbox, UseCheckboxProps } from '@chakra-ui/react'
import { Chip } from '.'

export type CheckboxChipProps = UseCheckboxProps & {
  children: string
}

export function CheckboxChip(props: CheckboxChipProps) {
  const { getInputProps, getCheckboxProps } = useCheckbox(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} aria-label={props.children} name={props.children} />
      <Chip {...checkbox} selected={props.isChecked}>
        {props.children}
      </Chip>
    </Box>
  )
}

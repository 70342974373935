import React, { forwardRef } from 'react'
import { DateInputField, DateInputFieldProps } from './DateInputField'

export const MonthInput = forwardRef<HTMLInputElement, DateInputFieldProps>(
  ({ label = 'Month', ...props }, ref) => {
    return (
      <DateInputField
        {...props}
        label={label}
        width="3.25rem"
        ref={ref}
        maxLength={2}
      />
    )
  }
)

MonthInput.displayName = 'MonthInput'

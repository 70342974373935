import React, { ReactNode } from 'react'

import { FormHelperText } from '@chakra-ui/react'
import { FormControl, FormControlProps } from '../FormControl'
import { FormLabel } from '../FormLabel'
import { FormErrorMessage } from '../FormErrorMessage'
import { Box } from '../Box'

import type { StyleProps } from '../../core/style-props'

export interface InputFieldProps
  extends Pick<
      FormControlProps,
      'id' | 'isDisabled' | 'isRequired' | 'label' | 'children' | 'as'
    >,
    StyleProps {
  /**
   * Used to provide feedback about an invalid input,
   * and suggest clear instructions on how to fix it.
   */
  errorMessage?: string
  /**
   * Used to provide message of the expected content
   * from the user.
   */
  hintText?: ReactNode
}

export const InputField = ({
  children,
  errorMessage,
  hintText,
  id,
  isDisabled = false,
  isRequired = false,
  label,
  as,
  ...props
}: InputFieldProps) => {
  return (
    <FormControl
      id={id}
      isDisabled={isDisabled}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
      label={label}
      as={as}
      {...props}
    >
      <FormLabel as={as ? 'legend' : undefined}>{label}</FormLabel>
      {hintText && <FormHelperText marginTop={1}>{hintText}</FormHelperText>}
      <FormErrorMessage marginTop={2}>{errorMessage}</FormErrorMessage>
      <Box marginTop={2} />
      {children}
    </FormControl>
  )
}

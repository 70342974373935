import { StylesConfig } from 'react-select'

const getAutoSelectStyles = (
  autoSuggestTheme: Record<string, any>,
  errorMessage: string | undefined
) => {
  const styles: StylesConfig = {
    container: (provided) => ({
      ...provided,
      ...autoSuggestTheme.container,
    }),
    control: (provided, state) => {
      const { isFocused, menuIsOpen } = state
      return {
        ...provided,
        ...autoSuggestTheme.control,
        ...(isFocused
          ? {
              borderColor: autoSuggestTheme.menu
                ? `${autoSuggestTheme.menu.borderColor} !important`
                : '',
            }
          : {}),
        ...(!menuIsOpen && isFocused
          ? {
              boxShadow: autoSuggestTheme.menu
                ? autoSuggestTheme.menu.boxShadow
                : '',
            }
          : {}),
        ':hover': {
          borderColor: autoSuggestTheme.menu
            ? autoSuggestTheme.menu.borderColor
            : '',
        },
        ...(errorMessage ? autoSuggestTheme._invalid : {}),
      }
    },
    menu: (provided) => {
      return {
        ...provided,
        ...autoSuggestTheme.menu,
        ...(errorMessage
          ? { borderColor: autoSuggestTheme._invalid.borderColor }
          : {}),
      }
    },
    option: (provided, state) => {
      const { isFocused } = state
      return {
        ...provided,
        ...autoSuggestTheme.option,
        '.option__label': autoSuggestTheme['.option__label'],
        minHeight: '4.75rem',
        height: 'auto',
        ':active': {
          backgroundColor: autoSuggestTheme._focus.backgroundColor,
        },
        ...(isFocused
          ? {
              ...autoSuggestTheme._focus,
              '.option__hintText': { color: autoSuggestTheme._focus.color },
              '.option__label': {
                color: autoSuggestTheme._focus.color,
                fontWeight: autoSuggestTheme['.option__label'].fontWeight,
              },
            }
          : { '.option__hintText': autoSuggestTheme['.option__hintText'] }),
      }
    },
    input: (provided) => ({
      ...provided,
      ...autoSuggestTheme.input,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      ...autoSuggestTheme.dropdownIndicator,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      ...autoSuggestTheme.indicatorSeparator,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      ...autoSuggestTheme.clearIndicator,
    }),
    valueContainer: (provided) => ({
      ...provided,
      ...autoSuggestTheme.valueContainer,
    }),
    singleValue: (provided) => ({
      ...provided,
      ...autoSuggestTheme.singleValue,
    }),
    menuList: (provided) => ({
      ...provided,
      ...autoSuggestTheme.menuList,
    }),
    menuPortal: (provided) => {
      const controlHeight = autoSuggestTheme.control.height
      const top = provided.top as number
      return {
        ...provided,
        zIndex: 2,
        top: `calc(${top}px - ${controlHeight})`,
      }
    },
  }

  return styles
}

export default getAutoSelectStyles

import React, { forwardRef } from 'react'
import { Box } from '../Box'
import { INPUT_TYPES } from '../TextInput'
import { useMultiStyleConfig } from '@chakra-ui/react'
import { Input, InputProps } from '../Input'
import { FormLabel } from '../FormLabel'

export type DateInputFieldProps = Pick<
  InputProps,
  | 'defaultValue'
  | 'id'
  | 'isDisabled'
  | 'isInvalid'
  | 'isRequired'
  | 'maxLength'
  | 'name'
  | 'onChange'
> & {
  label?: string
  width?: string
}

export const DateInputField = forwardRef<HTMLInputElement, DateInputFieldProps>(
  ({ id, width, label, ...props }, ref) => {
    const styles = useMultiStyleConfig('DateInput', props)
    const labelId = id && `${id}-label`

    return (
      <Box w={width}>
        <FormLabel
          id={labelId}
          htmlFor={id}
          sx={styles.label}
          requiredIndicator={<span></span>}
        >
          {label}
        </FormLabel>
        <Box marginTop={2} />
        <Input
          {...props}
          id={id}
          sx={styles.field}
          type={INPUT_TYPES.text}
          inputMode="numeric"
          pattern="[0-9]*"
          ref={ref}
        />
      </Box>
    )
  }
)

DateInputField.displayName = 'DateInputField'

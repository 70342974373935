import React, { forwardRef } from 'react'
import { BaseSelect, BaseSelectProps } from './BaseSelect'
import { InputField, InputFieldProps } from '../InputField'

export type SelectProps = BaseSelectProps & InputFieldProps

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    { errorMessage, label, hintText, id, isDisabled, isRequired, ...props },
    ref
  ) => {
    return (
      <InputField
        errorMessage={errorMessage}
        hintText={hintText}
        id={id}
        isDisabled={isDisabled}
        isRequired={isRequired}
        label={label}
      >
        <BaseSelect {...props} ref={ref} />
      </InputField>
    )
  }
)

Select.displayName = 'Select'

import React, { forwardRef } from 'react'
import {
  ListItem as ChakraListItem,
  ListItemProps as ChakraListItemProps,
  Flex,
  useStyles,
  Stack,
} from '@chakra-ui/react'

import type { StyleProps } from '../../core/style-props'

import { IconType } from '../Icon/iconRegistry'
import { Text } from '../Text'
import { Icon } from '../Icon'

type ListItemProps = Pick<ChakraListItemProps, 'children'> & StyleProps

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (props, ref) => {
    return <ChakraListItem {...props} ref={ref} />
  }
)

ListItem.displayName = 'ListItem'

type BulletListItem = {
  hint?: string
  iconName?: IconType
} & ListItemProps

export const BulletListItem = forwardRef<HTMLLIElement, BulletListItem>(
  ({ hint, iconName, children, ...rest }, ref) => {
    const styles = useStyles()
    const itemTextWeight = hint ? 'bold' : 'inherit'

    return (
      <ChakraListItem {...rest} ref={ref}>
        <Flex>
          {iconName && (
            <Icon icon={iconName} sx={styles.icon} role="presentation" />
          )}
          <Stack spacing={2}>
            <Text fontWeight={itemTextWeight}>{children}</Text>
            {hint && <Text sx={styles.hint}>{hint}</Text>}
          </Stack>
        </Flex>
      </ChakraListItem>
    )
  }
)

BulletListItem.displayName = 'BulletListItem'

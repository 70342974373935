import React from 'react'
import { components, InputProps as ReactSelectInputProps } from 'react-select'

const AutoSuggestInput = (props: ReactSelectInputProps) => {
  const newProps = { ...props }
  if (props.hasValue) {
    newProps.isHidden = false
  }
  return (
    <components.Input
      {...newProps}
      onFocusCapture={(e) => {
        // sets the focus on the end of the text in the input when clicking in the empty space of the input
        e.currentTarget.setSelectionRange(-1, -1)
      }}
    ></components.Input>
  )
}

export default AutoSuggestInput

import { useColorMode } from '@chakra-ui/react'
import { colors, light } from '../themes/dna/foundations/colors'
import { ColorToken } from './color-token'
import { Leaves } from './types'

const objectValue = (child: Record<string, unknown>, prefix: string) => {
  const copy = {}

  Object.entries(child).forEach(([key, value]) => {
    const pre = prefix.length ? prefix + '.' + key : ''

    if (typeof value === 'string') Object.assign(copy, { [key]: pre })
    else
      Object.assign(copy, {
        [key]: objectValue(value as Record<string, unknown>, pre),
      })
  })

  return copy
}

export const useColorTheme = () => {
  const { colorMode } = useColorMode()

  const getColorValue = (colorName: Leaves<typeof light>) => {
    return `${colorMode}.${colorName}` as ColorToken
  }

  return {
    colors: objectValue(colors[colorMode], colorMode) as Record<
      string,
      Record<string, ColorToken>
    >,
    getColorValue,
  }
}

import React, { ReactNode, forwardRef } from 'react'
import {
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  useMultiStyleConfig,
} from '@chakra-ui/react'

import { Text } from '../Text'

export type BaseCheckboxProps = ChakraCheckboxProps & {
  children?: ReactNode
  label: ChakraCheckboxProps['children']
  hintText?: string
}

export const BaseCheckbox = forwardRef<HTMLInputElement, BaseCheckboxProps>(
  ({ children, hintText, label, ...props }, ref) => {
    const styles = useMultiStyleConfig('Checkbox', {})

    return (
      <>
        <ChakraCheckbox {...props} sx={styles.container} ref={ref}>
          {label}
          {hintText && (
            <Text as="span" sx={styles.hintText} textStyle="caption">
              {hintText}
            </Text>
          )}
        </ChakraCheckbox>
        {children && <Box sx={styles.content}>{children}</Box>}
      </>
    )
  }
)
